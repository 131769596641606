import styled from 'styled-components';
const RegularFont = 'Rubik-Regular';
// const BoldFont = 'Rubik-Medium';
// const ExtraBold = 'Rubik-Bold';

const Layout = styled.div`
  padding: 32px 40px 20px 32px;
  padding: ${({ padding }) => padding ? '0px' : '32px 40px 20px 32px'};
  width: 100%;
  float: left;
  font-family: ${RegularFont};
  margin-bottom: 100px;

  @media (max-width: 500px) {
    padding: 30px 10px;
  }
`;

const SurveyActionsDropdown = styled.div`

.dropdown{
      .sub-menu-wrapper{
        width: 250px;
        background: white;
        z-index:99999;
        left: 40px;
        border: 1px solid rgba(0,92,135,0.30);
        border-radius: 6px;
        padding: 12px 0px;
        box-shadow: inset 0 3px 5px rgba(0,0,0,.125);
        .sub-menu{
          color: #007AB1;
          font-family: Rubik-medium;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          padding: 4px 0px 4px 12px;
          cursor:pointer;
        }
      }
      button{
        background: none;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 5px;
        margin-top: ${(isEvent)=> isEvent && '3px'};
        ${'' /* padding-left: ${(isEvent)=> isEvent && '0px'}; */}
        padding-left: 0px;
        padding-right: 0px;
        margin-right: 0px;
        margin-left: 0px;
        &:active{
          background: none;
          border: none;
        }
      }
      .dropdown-menu.show{
        display: inline-flex;
        padding: 12px;
        justify-content: center;
        align-items: center;
        transform: translate3d(-13px, 50px, 0px);
        border-radius: 4px;
        border: 1px solid rgba(0, 92, 135, 0.30);
        background: #FFF;
        display: flex;
        flex-direction: column;
        margin-left: -175px;
        a{
          color: #007AB1;
          font-family: Rubik-medium;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          display:flex;
          >svg{
            display:flex;
            margin:auto 0 auto auto;
            transform:rotate(0deg);
          }
          &:active{
            background: none;
          }
        }
        hr{
          width: 90%;
          color: rgba(0, 92, 135, 0.50);
        }
      }
    }
`

export {Layout, SurveyActionsDropdown}