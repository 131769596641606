import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { MainEventsContainer } from "../../MainEvents/styles";
import { connect } from "react-redux";
import { getSurveyDetails } from "../../../redux/actions";
import { fetchApi, /*getPermissionStatus*/ } from "../../../utils/methods";
import { FilterButton } from "./style";
import SurveyCard from "./SurveyCard";
import CustomSurveyPopUp from "./CustomSurveyPopUp";
import { FetchSurveyLibrariesByStatus } from "../../../redux/constants/apiConstants";
import SkeletonLoder from "../../common/skeletonLoder";

const filterButtons = [
  { id: 1, name: "From Woliba" },
  { id: 2, name: "From My Organization" },
];
// const menuOptions = [{tab:"Surveys",path:"/company/surveys"}, {tab:"Completed",path:"/company/surveys-completed"}, {tab:"Library",value:"/company/survey-library"}]

const SurveyLibrary = (props) => {
  const { history, getSurveyDetails, userPermissions, role,companyInfo } = props;
  const { state } = history.location;

  const [organizationSurveys, setOrganizationSurveys] = useState([]);
  const [activeFilterButton, setActiveFilterButton] = useState(filterButtons[0]);
  const [isCustomSurvey, setIsCustomSurvey] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const componentMounted = useRef(true);

  // const createCustomSurvey = getPermissionStatus("Create custom survey", userPermissions);

  useEffect(() => {
    const initialTabId = state?.tabId || 1;
    const initialFilter = filterButtons.find((button) => button.id === initialTabId) || filterButtons[0];
    if(history?.location?.state?.type === "organization"){
      setActiveFilterButton(filterButtons[1]);
      getSurveys("company");

    }else{
      setActiveFilterButton(initialFilter);
      if (!organizationSurveys.length) {
        getSurveys(initialFilter.id === 2 ? "company" : null);
      }

    }


    return () => {
      componentMounted.current = false;
    };
  }, []);
  

  const getSurveys = async (status) => {
    setIsLoading(true);
    const url = FetchSurveyLibrariesByStatus + (`${status ===  "woliba"? "": (status ===  "company" || activeFilterButton.id === 2 )? `/${companyInfo.id}`:""}` );

    try {
      const res = await fetchApi(url, "GET");
      if (res.status === "success") {
        setOrganizationSurveys(res.data);
      } else {
        toast.error(res.data.msg);
      }
    } catch (error) {
      toast.error(error);
    } finally {
      if (componentMounted.current) {
        setIsLoading(false);
      }
    }
  };

  const renderCards = (data, type) => (
    <div style={{ display: data.length ? "flex" : "block", width: "100%", flexWrap: "wrap" }}>
      {data.length ? (
        data.map((survey, index) => (
          <SurveyCard
            key={index}
            role={role}
            survey={survey}
            getSurveyDetails={getSurveyDetails}
            userPermissions={userPermissions}
            type={type}
            getSurveys={getSurveys}
          />
        ))
      ) : (
        <div>No data found</div>
      )}
    </div>
  );

  return (
    <React.Fragment>
      <MainEventsContainer marginTop="1" style={{flexDirection:"column"}}>

        <div style={{ display: "flex", margin: "0px 0px 25px 0px" }}>
          {filterButtons.map((item, index) => (
            <FilterButton
              key={index}
              active={activeFilterButton.id === item.id}
              onClick={() => {
                history.push({ pathname: "/company/surveys/library", state: { tabId: item.id } });
                setActiveFilterButton(item);
                getSurveys(item.id === 1 ? "woliba" : item.id === 2 ? "company" : "");
              }}
            >
              <button>{item.name}</button>
            </FilterButton>
          ))}
        </div>

        {isLoading ? (
          <div style={{ display: "flex", width: "100%", flexWrap: "wrap" }}>
            {Array.from({ length: 20 }).map((_, index) => (
              <SkeletonLoder key={index} width={"395px"} height={"200px"} style={{margin:"0px 20px 20px 0px"}}/>
            ))}
          </div>
        ) : (
          renderCards(organizationSurveys, activeFilterButton.id === 2 ? "organization" : "woliba")
        )}

        {isCustomSurvey && <CustomSurveyPopUp showModal={isCustomSurvey} onClose={() => setIsCustomSurvey(false)} />}
      </MainEventsContainer>
    </React.Fragment>
  );
};

SurveyLibrary.propTypes = {
  history: PropTypes.object,
  getSurveyDetails: PropTypes.func.isRequired,
  userPermissions: PropTypes.array,
  role: PropTypes.string,
  companyInfo: PropTypes.object,
};

const mapStateToProps = (state) => ({
  userPermissions: state.profileData.userPermissions,
});

const mapDispatchToProps = (dispatch) => ({
  getSurveyDetails: (surveyId, history, route) => dispatch(getSurveyDetails(surveyId, history, route)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SurveyLibrary));
